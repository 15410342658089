import React, { useEffect, useMemo, useRef } from 'react';
import Text from 'modules/Text';
import api_requests from 'resources/api_requests';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import _ from 'lodash';
import utils from 'resources/utils';
import constant from 'resources/constant';
import OutlinedTextField from 'modules/OutlinedTextField';
import Button from 'modules/Button';
import { toast } from 'react-toastify';
import { get_user_details } from 'actions/app';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.darkGrey2,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.primary,
		},

		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			color: theme.colors.black,
		},

		'& .MuiInputLabel-outlined': {
			color: theme.colors.darkGrey2,
		},

		'& .MuiInputLabel-outlined.Mui-focused': {
			color: theme.colors.primary,
		},

		'& .MuiInputLabel-outlined.Mui-error': {
			color: theme.colors.red2,
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.colors.red2,
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: `translate(20px, -6px) scale(0.75)`,
		},
	},

	helper_text: {
		color: `${theme.colors.red2} !important`,
		fontSize: 12,
	},
	wrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	login_form: { width: '50%', height: '100%' },
	cta_btn: { width: 130, display: 'flex', alignItems: 'center', marginTop: 20 },
}));

const Login = () => {
	const [mobile, set_mobile] = React.useState('');
	const [password, set_password] = React.useState('');
	const [login_failed, set_login_failed] = React.useState(false);
	const [login_details, set_login_details] = React.useState({});
	const [show_pass, set_show_pass] = React.useState(false);
	const dispatch = useDispatch();

	const classes = useStyles();

	const _proceed = async (e) => {
		e.preventDefault();
		if (mobile.length != 10) {
			toast.error('10 digit number accepted only, if testing, please use testing credentials provided', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}

		try {
			const res = await api_requests.user_auth({ mobile, password });
			set_login_failed(false);
			utils._storeData(constant.NAME, res.data.info.first_name + ' ' + res.data.info.last_name);
			utils._storeData(constant.MOBILE, res.data.info.mobile);
			utils._storeData(constant.USER_ID, res.data.info.id);
			utils._storeData(constant.OWNER_ID, res.data.info.owner_id);
			utils._storeData(constant.TYPE_OF_OWNER, res.data.info.type_of_owner);
			utils._storeData(constant.AUTH_TOKEN, res.data.token);
			// utils._storeData(constant.TYPE_OF_USER, res.data.info.user_type);
			set_login_details(res.data);
			dispatch(get_user_details());
		} catch (error) {
			set_login_failed(true);
		}
	};

	const login_btn_disabled = useMemo(() => {
		let value = true;
		if (!_.isEmpty(mobile) && !_.isEmpty(password)) value = false;
		return value;
	}, [mobile, password]);

	return (
		<div className={classes.wrapper}>
			<img src={ImageLinks.logo} width={180} />
			<Text medium style={{ fontSize: 16, marginTop: 20 }}>
				Enter your Email id and Password to continue
			</Text>
			<form onSubmit={_proceed}>
				<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
					<OutlinedTextField
						type='number'
						value={mobile}
						notched
						name='mobile'
						textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
						onChange={(e) => set_mobile(e.target.value)}
						label='Mobile Number'
						className={classes.root}
						style={{ marginTop: 30, width: 235 }}
						InputProps={{
							startAdornment: (
								<Text medium style={{ width: 40, fontSize: 16, color: theme.colors.lightGrey2 }}>
									+91
								</Text>
							),
						}}
					/>

					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<OutlinedTextField
							type={show_pass ? 'text' : 'password'}
							value={password}
							textStyle={{ fontSize: 16, color: theme.colors.darkGrey2 }}
							name='password'
							error={login_failed}
							helperText={
								login_failed ? (
									<div className='d-flex'>
										<img width={16} height={16} src={ImageLinks.exclamation_error} style={{ marginRight: 10, marginTop: 5 }} />
										<Text style={{ fontSize: 12, letterSpacing: 'unset', lineHeight: 1.3 }}>
											Wrong password. Try again or click Forgot password to reset.
										</Text>
									</div>
								) : null
							}
							InputProps={{
								endAdornment: (
									<img
										className='cursor-pointer'
										onClick={() => set_show_pass((prev) => !prev)}
										width={24}
										height={24}
										src={show_pass ? ImageLinks.eye_open : ImageLinks.eye_close}
									/>
								),
							}}
							onChange={(e) => set_password(e.target.value)}
							inputProps={{ style: { letterSpacing: 4 } }}
							label='Password*'
							className={classes.root}
							style={{ width: 275 }}
							FormHelperTextProps={{
								classes: {
									root: classes.helper_text,
								},
							}}
						/>
					</div>
				</div>
			</form>
			<Button
				type='round'
				text='Login'
				size='large'
				right_icon={login_btn_disabled ? ImageLinks.arrow_right_grey : ImageLinks.arrow_right_white_no_fill}
				disabled={login_btn_disabled}
				className={classes.cta_btn}
				onClick={_proceed}
			/>
		</div>
	);
};

export default Login;
