import { LOGIN_DETAILS_RECEIVED, LOGIN_FAILED, SHOW_LOADER, HIDE_LOADER, USER_DETAILS_RECEIVED } from 'actions/actionConstants';
import _ from 'lodash';
import constant from 'resources/constant';
import utils from 'resources/utils';

const initialState = {
	user_details: {},
	business_accounts: [],
	selected_business_id: null,
	zones: [],
	business_properties: {},
	bop_packages: [],
	permissions: [],
	status: constant.REDUX_STATE.IDLE,
	is_loading: false,
	selected_business_account: {},
	app_configs: {},
	network_list: [],
};

const app = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_DETAILS_RECEIVED: {
			// const businessId = action.selected_business.business_id;
			// const parent_id = action.selected_business.parent_id;
			// utils.setRequestAuthHeaderBusinessId(businessId, parent_id);

			return {
				...state,
				status: constant.REDUX_STATE.SUCCEEDED,
				business_accounts: action.business_accounts,
				// selected_business_id: businessId,
				permissions: action.permissions,
				bop_packages: action.bop_packages,
				selected_business_account: action.selected_business,
				app_configs: action.app_configs,
				network_list: action.network,
			};
		}

		case USER_DETAILS_RECEIVED: {
			return {
				...state,
				user_details: {
					name: action.name,
					mobile: action.mobile,
					user_id: action.user_id,
				},
			};
		}

		case LOGIN_FAILED:
			return { ...state, status: constant.REDUX_STATE.FAILED };

		case SHOW_LOADER:
			return { ...state, is_loading: true };
		case HIDE_LOADER:
			return { ...state, is_loading: false };

		default:
			return state;
	}
};

export default app;
