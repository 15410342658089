import { makeStyles } from '@material-ui/core';
import { drawerWidth } from './helper';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		boxShadow: 'unset',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		color: theme.colors.white,
		background: theme.colors.sideNavPrimary,
	},
	menuButton: {
		marginRight: 0,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		color: theme.colors.white,
		background: theme.colors.sideNavPrimary,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		color: theme.colors.white,
		background: theme.colors.sideNavPrimary,
		borderWidth: 0,
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
		color: theme.colors.white,
		background: theme.colors.sideNavPrimary,
		borderWidth: 0,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '8px 16px',
		color: theme.colors.white,

		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	sidebarBox: {
		display: 'flex',
		height: `calc(100vh - 64px)`,
		justifyContent: 'space-between',
		flexDirection: 'column',
	},
	nested: {
		paddingLeft: 12,
	},
	user_sub_details: {
		fontSize: 12,
		lineHeight: '14px',
		color: 'inherit',
	},
	top_navmenu_container: {
		height: 'auto',
		padding: '0 8px',
	},
	expanded_drawer_arrow: {
		color: theme.colors.sideNavPrimary,
		zIndex: 1201,
		position: 'absolute',
		left: '224px',
		top: '50%',
		padding: 6,
		background: theme.colors.sideNavSecondary,
		'&:hover': {
			background: theme.colors.lightPurple,
			color: theme.colors.sideNavPrimary,
		},
	},
	icons_wrap: {
		backgroundColor: 'transparent',
		height: 30,
		width: 30,
		padding: 3,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		'&:hover': {
			backgroundColor: theme.colors.sideNavHover,
		},
	},
	icons_wrap_active: {
		backgroundColor: theme.colors.sideNavSecondary,
		height: 30,
		width: 30,
		padding: 3,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
	},
	business_details_wrap: {
		padding: '8px 20px',
		backgroundColor: theme.colors.sideNavHover,
		marginTop: 10,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
}));
