import React from 'react';
import theme from 'resources/theme';
import ContainedButton from './ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import { Badge, makeStyles } from '@material-ui/core';
import utils from 'resources/utils';

const useStyles = makeStyles(() => ({
	filter_btn: {
		backgroundColor: theme.colors.lightPurple,
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
		'&:hover': {
			boxShadow: `0px 2px 16px rgba(102, 102, 102, 0.3)`,
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	custom_badge: {
		background: theme.colors.lightPurple5,
		top: '5%',
		right: '5%',
		color: theme.colors.white,
	},
}));

const FilterButton = ({ handle_click, total_filters, wrapper_styles = {}, track_event = { event: '', properties: {} } }) => {
	const classes = useStyles();
	const on_badge_click = () => {
		handle_click();
	};
	return (
		<Badge
			overlap='circular'
			badgeContent={total_filters}
			classes={{
				badge: classes.custom_badge,
			}}>
			<ContainedButton disableElevation className={classes.filter_btn} style={wrapper_styles} onClick={on_badge_click}>
				<img className='purple-arrow' width={17} height={12} src={ImageLinks.filter_pink} alt='' />
				<img className='white-arrow' style={{ display: 'none' }} width='18' src={ImageLinks.filter} alt='' />
			</ContainedButton>
		</Badge>
	);
};

export default FilterButton;
