/**
 * constant file for whole app
 */

import _ from 'lodash';

const env = {
	STAGING: 'staging',
	PRODUCTION: 'production',
};

let ENVIRONMENT = env.STAGING;
// const ENVIRONMENT = env.PRODUCTION;

const constant = {
	env,
	ENVIRONMENT,
	BASE_URL: {
		DEFAULT: 'API_URL',
		STORE: 'API_STORE_URL',
		TRACE: 'API_TRACE_URL',
	},
	API_URL: '',
	API_STORE_URL: '',
	API_TRACE_URL: '',
	TRACE_FE_URL: '',
	REDUX_STATE: {
		IDLE: 'idle',
		SUCCEEDED: 'succeeded',
		FAILED: 'failed',
	},
	NAME: 'name',
	MOBILE: 'mobile',
	USER_ID: 'user_id',
	AUTH_TOKEN: 'auth_token',
	TYPE_OF_OWNER: 'type_of_owner',
	OWNER_ID: 'owner_id',
};

export default constant;
