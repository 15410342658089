import React, { useEffect, useState } from 'react';
import MyDashboardEmbedComponent from './components/MyDashboardEmbedComponent';
import api_requests from 'resources/api_requests';
import _ from 'lodash';
import Select from 'modules/Select';
import utils from 'resources/utils';

function Dashboard() {
	const [jwt, set_jwt] = useState('');
	const [charts, set_charts] = useState([]);
	const [selected_business, set_selected_business] = useState([]);
	const [business_list, set_business_list] = useState([]);

	useEffect(() => {
		get_charts_list();
	}, []);

	const get_charts_list = async () => {
		const res = await api_requests.get_charts(utils._retrieveData('user_id'));
		set_business_list(res.data.businesses);
		set_jwt(res.data.token);
		set_charts(res.data.items);
		set_selected_business(
			_.map(res.data.businesses, (business) => {
				return business.id;
			}),
		);
	};

	const handle_select_drop_down = (value) => {
		let val = [...selected_business];
		const index = _.indexOf(val, value);
		if (index !== -1) {
			val.splice(index, 1);
		} else {
			val.push(value);
		}
		set_selected_business(val);
	};
	return (
		<>
			{business_list?.length > 0 && (
				<Select
					defaultOption={{ title: 'select', value: null }}
					value={selected_business}
					options={_.map(business_list, (business) => {
						return {
							title: business.name,
							value: business.id,
						};
					})}
					handleChange={(val) => {
						handle_select_drop_down(val);
					}}
					containerStyles={{ height: 35, width: 160, margin: 10, marginLeft: 'auto' }}
					menu_styles={{ marginTop: 40 }}
					text_props={{ semi: true }}
					truncate_title={(title) => {
						return _.truncate(title, { length: 16 });
					}}
					multiple={true}
				/>
			)}
			<div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
				{jwt && charts.length > 0 && (
					<>
						{_.map(charts, (chart) => {
							return <MyDashboardEmbedComponent chart={chart} token={jwt} selected_business={selected_business} />;
						})}
					</>
				)}
			</div>
		</>
	);
}

export default Dashboard;
