import React, { useEffect } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import theme from 'resources/theme';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Routes from 'resources/Routes';
import _ from 'lodash';
import store from 'store';
import browserHistory from 'browserHistory';
import { MuiThemeProvider } from '@material-ui/core';
import Loader from 'modules/Loader';
import ErrorBoundary from 'modules/ErrorBoundary';
import Dashboard from 'views/Dashboard/Dashboard';
import constant from 'resources/constant';
import Login from 'views/Login/Login';
import utils from 'resources/utils';
import { get_user_details, login_failed, user_details_received } from 'actions/app';
import Sidebar from 'views/Sidebar/Sidebar';

Modal.setAppElement('#root');
class App extends React.Component {
	render() {
		return (
			<ErrorBoundary>
				<MuiThemeProvider theme={theme}>
					<ToastContainer />
					<Provider store={store}>
						<Router history={browserHistory}>
							<Switch>
								<Route path='/' component={PrimaryComponent} />
							</Switch>
						</Router>
					</Provider>
				</MuiThemeProvider>
			</ErrorBoundary>
		);
	}
}

const PrimaryComponent = ({ history }) => {
	const { app } = useSelector((state) => state, shallowEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(get_user_details());
	}, []);

	if (app.status === constant.REDUX_STATE.IDLE) {
		return null;
	}

	if (app.status !== constant.REDUX_STATE.SUCCEEDED) {
		return (
			<Switch>
				<Route path='/' exact={true} component={Login} />
				<Redirect to='/' />
			</Switch>
		);
	}
	return <MainComponent />;
};

const MainComponent = ({ history }) => {
	const { app } = useSelector((state) => state, shallowEqual);
	const [open, setOpen] = React.useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		const name = utils._retrieveData(constant.NAME);
		const mobile = utils._retrieveData(constant.MOBILE);
		const user_id = utils._retrieveData(constant.USER_ID);

		if (name) {
			dispatch(user_details_received(name, mobile, user_id));
		} else {
			dispatch(login_failed());
		}
	}, []);

	return (
		<div
			style={{
				flex: 1,
				display: 'flex',
				backgroundColor: theme.colors.sideNavPrimary,
				height: '100%',
			}}>
			<Loader visible={app.is_loading} />

			<div style={open ? { width: 240 } : { width: 72 }}>
				<Sidebar set_rider_data={() => {}} open={open} setOpen={setOpen} />
			</div>
			<div
				style={{
					flex: 1,
					height: '100%',
					position: 'relative',
					background: '#FAFAFA',
					borderTopLeftRadius: 30,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Switch>
					{_.map(
						[
							{
								path: Routes.HOME.path,
								exact: Routes.HOME.exact,
								component: Dashboard,
							},
							{
								path: Routes.DASHBOARD.path,
								exact: Routes.DASHBOARD.exact,
								component: Dashboard,
							},
						],
						(route, idx) => {
							return <Route key={idx} path={route.path} exact={route.exact} component={route.component} />;
						},
					)}

					<Redirect to='/' />
				</Switch>
			</div>
		</div>
	);
};

export default App;
