import React, { useEffect, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import FilterButton from 'modules/FilterButton';
import Filters from 'modules/Filters';

const MyDashboardEmbedComponent = ({ chart, token = '', selected_business }) => {
	const { chart_id, base_url, filter } = chart;
	const [show_filter_menu, set_show_filter_menu] = useState(false);
	const [applied_filters, set_applied_filters] = useState({});
	useEffect(() => {
		const sdk = new ChartsEmbedSDK({ baseUrl: base_url });
		const dashboardEmbed = sdk.createChart({
			chartId: chart_id,
			height: 300,
			width: 400,
			getUserToken: () => {
				return token;
			},
			filter: { ...applied_filters, 'owner.id': { $in: selected_business } },
			showAttribution: false,
		});

		dashboardEmbed.render(document.getElementById(`chart-container-${chart_id}`));
	}, [chart_id, token, applied_filters, selected_business]);

	return (
		<>
			<div id={`chart-container-${chart_id}`} />
			<div>
				<FilterButton
					handle_click={() => {
						set_show_filter_menu(!show_filter_menu);
					}}
				/>
			</div>
			{show_filter_menu && (
				<Filters
					close={() => set_show_filter_menu(false)}
					filters={filter}
					set_applied_filters={set_applied_filters}
					applied_filters={applied_filters}
				/>
			)}
		</>
	);
};

export default MyDashboardEmbedComponent;
