import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import constant from 'resources/constant';

const initialize_app = async (response) => {
	const json = await response.json();
	constant.ENVIRONMENT = json.ENVIRONMENT;
	constant.API_URL = json.API_URL;
	constant.API_STORE_URL = json.API_STORE_URL;
	constant.API_TRACE_URL = json.API_TRACE_URL;
	constant.TRACE_FE_URL = json.TRACE_FE_URL;

	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
};

fetch('/env_constant.json').then(initialize_app);
