import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import app from "reducers/app";
import thunk from "redux-thunk";
import constant from "resources/constant";

const rootReducer = combineReducers({
  app,
});

const store = createStore(
  rootReducer,
  constant.ENVIRONMENT === constant.env.PRODUCTION
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk))
);

export default store;
