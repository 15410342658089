import history from 'browserHistory';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import { LOGIN_DETAILS_RECEIVED, LOGIN_FAILED, SHOW_LOADER, HIDE_LOADER, USER_DETAILS_RECEIVED } from './actionConstants';

export const get_user_details =
	(from_biz_switch = false) =>
	async (dispatch) => {
		const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
		const type_of_owner = utils._retrieveData(constant.TYPE_OF_OWNER);
		const selected_business = utils._retrieveData(constant.SELECTED_BUSINESS);
		try {
			if (!auth_token) {
				dispatch(login_failed());
				return Promise.resolve();
			}

			utils.setRequestAuthHeader(auth_token, type_of_owner);
			// const business_accounts = await api_requests.get_business_accounts();

			// const permissions = (await api_requests.get_permissions(selected_business.business_id)).data;
			const permissions = [];
			// const bop_packages = (await api_requests.get_bop_packages()).data;
			// const app_configs = (await api_requests.get_common_configs(selected_business.business_id)).data;
			// const teams = await api_requests.get_joined_teams(selected_business.business_id);
			// const network = (await api_requests.get_network_list(selected_business.business_id)).data;
			// todo put get user details API

			// placing this as last since it sets redux state to succeeded, zones are necessary to run the app
			// dispatch(login_details_received(business_accounts.data, permissions, bop_packages, selected_business, app_configs, network));
			dispatch(login_details_received([], [], [], {}, {}, []));

			return Promise.resolve();
		} catch (err) {
			dispatch(login_failed());
			return Promise.reject(err);
		}
	};

const login_details_received = (business_accounts, permissions, bop_packages, selected_business, app_configs, network) => ({
	type: LOGIN_DETAILS_RECEIVED,
	business_accounts,
	permissions,
	bop_packages,
	selected_business,
	app_configs,
	network,
});

export const user_details_received = (name, mobile, user_id) => ({
	type: USER_DETAILS_RECEIVED,
	name,
	mobile,
	user_id,
});

export const login_failed = () => {
	utils.logout();
	history.push('/');
	return { type: LOGIN_FAILED };
};

export const show_loader = () => async (dispatch) => {
	dispatch({ type: SHOW_LOADER });
};

export const hide_loader = () => async (dispatch) => {
	dispatch({ type: HIDE_LOADER });
};
