import { createTheme } from '@material-ui/core';

const basicTheme = createTheme({
	palette: {
		primary: {
			main: '#492897',
		},
		secondary: {
			main: '#492897',
		},
		white: {
			main: '#ffffff',
		},
		success: {
			main: '#0F8560',
		},
		error: {
			main: '#BC0000',
		},
	},
	overrides: {
		RaAutocompleteSuggestionList: {
			suggestionsContainer: {
				zIndex: 9000,
			},
		},
	},
});

const theme = {
	colors: {
		primary: '#492897',
		sideNavPrimary: '#492897',
		sideNavText: '#fff',
		sideNavSecondary: '#E4DCF3',
		sideNavHover: '#684CA9',
		lightPurple: '#E4DCF3',
		white: '#ffffff',
	},
	...basicTheme,
};

export default theme;
