import axios from "axios";
import { EventSourcePolyfill } from "event-source-polyfill";
import qs from "qs";
import _ from "lodash";
import { toast } from "react-toastify";
import store from "store";
import Cookies from "universal-cookie";
import constant from "./constant";

const cookies = new Cookies();

const url_variants = (base_url = constant.BASE_URL.DEFAULT) => {
  let API_URL;
  API_URL = constant[base_url];
  return API_URL;
};

let utils = {
  request: async ({
    version = 1,
    token,
    headers = {},
    base_url = constant.BASE_URL.DEFAULT,
    show_error = true,
    ...rest
  }) => {
    const config = rest;
    try {
      const API_URL = url_variants(base_url);

      const res = await axios.request({
        baseURL: API_URL + `v${version}.0/`,
        timeout: 3600 * 1000,
        headers: {
          ...(token && {
            Authorization: "Bearer " + token,
          }),
          ...headers,
        },
        ...config,
      });

      return Promise.resolve(res.data);
    } catch (error) {
      if (show_error) {
        toast.error(
          _.get(error, "response.data.message") ||
            _.get(error, "response.data.error.message") ||
            "",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
      if (error.response.data.statusCode === 401) {
        window.open("/", "_self");
      }
      return Promise.reject(error);
    }
  },

  stream_request: async ({
    version = 1,
    token,
    headers = {},
    base_url = constant.BASE_URL.DEFAULT,
    show_error = true,
    ...rest
  }) => {
    const config = rest;
    try {
      const API_URL = url_variants(base_url);
      const token = utils._retrieveData(constant.AUTH_TOKEN);
      const owner_id = utils._retrieveData(constant.OWNER_ID);
      const owner_type = utils._retrieveData(constant.TYPE_OF_OWNER);
      const parent_id = utils._retrieveData(constant.PARENT_ID);

      const api_url =
        API_URL +
        `v${version}.0/` +
        config.url +
        "?" +
        qs.stringify(config.params, { encode: false });

      const sse_events = new EventSourcePolyfill(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "owner-id": owner_id,
          "type-of-owner": owner_type.id,
          "parent-id": parent_id,
          ...headers,
        },
      });
      sse_events.onmessage = (event) => {
        if (event.type === "message" && event.lastEventId) {
          return config.msg_handler(event.data);
        }
      };
      sse_events.onerror = (error) => {
        sse_events.close();
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },

  setCustomHeader: (headers) => {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      ...headers,
    };
  },
  _storeData: (name, value) => {
    cookies.set(name, value, { path: "/" });
  },
  _retrieveData: (name) => {
    return cookies.get(name);
  },
  _removeData: (name) => {
    return cookies.remove(name, { path: "/" });
  },
  setRequestAuthHeader: (accessToken, type) => {
    utils.setCustomHeader({
      Authorization: "Bearer " + accessToken,
      "type-of-owner": type.id,
    });

    utils._storeData(constant.TYPE_OF_OWNER, type);
    utils._storeData(constant.AUTH_TOKEN, accessToken);
  },
  setRequestAuthHeaderBusinessId: (businessId, parent_id) => {
    utils.setCustomHeader({
      "owner-id": businessId,
      "parent-id": parent_id,
    });
    utils._storeData(constant.OWNER_ID, businessId);
    utils._storeData(constant.PARENT_ID, parent_id);
  },
  setRequestOwnerId: (type) => {
    utils.setCustomHeader({
      "type-of-owner": type.id,
    });

    utils._storeData(constant.TYPE_OF_OWNER, type);
  },
  logout: () => {
    axios.defaults.headers.common = {};
    utils._removeData(constant.AUTH_TOKEN);
    utils._removeData(constant.TYPE_OF_OWNER);
    utils._removeData(constant.OWNER_ID);
    utils._removeData(constant.SELECTED_BUSINESS);
    utils._removeData(constant.NAME);
    utils._removeData(constant.MOBILE);
    utils._removeData(constant.USER_ID);
  },
  get_access_token: () => {
    return utils._retrieveData(constant.AUTH_TOKEN);
  },
  get_business_id: () => {
    return store.getState().app.selected_business_id;
  },
  format_currency: (number) => {
    return number.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
      style: "currency",
      currency: "INR",
    });
  },
  downloadBlob: (content, filename, contentType) => {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  },
  dot_in_between_string: (string_name) => {
    if (string_name.length < 10) return string_name;
    const first_five = string_name.slice(0, 5);
    const last_five = string_name.slice(-5);
    return `${first_five}...${last_five}`;
  },
  convert_hex_to_rgba: (hexCode, opacity) => {
    let hex = hexCode.replace("#", "");

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  },
  remove_null_and_empty_from_obj: (obj, acc = {}, removeEmpty = false) => {
    return Object.entries(obj)
      .filter(
        ([, val]) =>
          val !== null &&
          !(removeEmpty && typeof val === "string" && val.trim() == "")
      )
      .reduce((acc, [key, val]) => {
        const accumulator = val && Array.isArray(val) ? [] : {};
        acc[key] =
          val && typeof val === "object"
            ? utils.remove_null_and_empty_from_obj(
                val,
                accumulator,
                removeEmpty
              )
            : val;
        return acc;
      }, acc);
  },
  mobile_slice: (value) => {
    if (!value) return;
    let mobile = "";
    if (value.charAt(0) == "+") {
      mobile = value.replace(/[^a-zA-Z0-9+]/g, "").substring(3);
    } else if (value.charAt(0) == "0") {
      mobile = value.replace(/[^a-zA-Z0-9]/g, "").substring(1);
    } else {
      mobile = value.replace(/[^a-zA-Z0-9]/g, "");
    }
    return mobile;
  },
  remove_null_and_empty_from_obj: (obj, acc = {}, removeEmpty = false) => {
    return Object.entries(obj)
      .filter(
        ([, val]) =>
          val !== null &&
          !(removeEmpty && typeof val === "string" && val.trim() == "")
      )
      .reduce((acc, [key, val]) => {
        const accumulator = val && Array.isArray(val) ? [] : {};
        acc[key] =
          val && typeof val === "object"
            ? utils.remove_null_and_empty_from_obj(
                val,
                accumulator,
                removeEmpty
              )
            : val;
        return acc;
      }, acc);
  },
  nFormatter: (num) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol
      : num;
  },

  get_coords_aerial_distance: (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  },
};

export default utils;
