import _ from 'lodash';
import utils from './utils';
import constant from './constant';

const api_requests = {
	user_auth: (data) => {
		return utils.request({
			url: '/admin/auth',
			method: 'POST',
			data,
		});
	},
	get_charts: (admin_id) => {
		return utils.request({
			url: '/store/charts',
			method: 'GET',
			params: {
				admin_id: parseInt(admin_id),
			},
			base_url: constant.BASE_URL.STORE,
		});
	},
};

export default api_requests;
