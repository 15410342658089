export const LOGIN_DETAILS_RECEIVED = "LOGIN_DETAILS_RECEIVED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const USER_DETAILS_RECEIVED = "USER_DETAILS_RECEIVED";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const REMOVE_ALL_ALERTS = "REMOVE_ALL_ALERTS";

export const SUPPORT_MODAL_TOGGLE = "SUPPORT_MODAL_TOGGLE";
export const TOGGLE_NOTIF_MODAL = "TOGGLE_NOTIF_MODAL";
