import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from 'resources/theme';

const Loader = ({ visible = false }) => {
	return (
		<div
			style={{
				position: 'absolute',
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
				zIndex: 9999,
				pointerEvents: visible ? 'auto' : 'none',
			}}>
			<Backdrop
				sx={{
					color: theme.colors.white,
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={visible}>
				<CircularProgress color={'primary'} />
			</Backdrop>
		</div>
	);
};

export default Loader;
