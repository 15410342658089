import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Text from './Text';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import { Dialog, DialogContent } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import Select from './Select';
import ContainedButton from './ContainedButton';
import OutlinedTextField from './OutlinedTextField';

const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderRadius: '35px 0px 0px 0px',
		padding: '30px 30px 27px 30px',
		zIndex: 1200,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 10,
		boxShadow: '0px 0px 16px 0px rgba(102, 102, 102, 0.10)',
	},
}));

const initial_date_picker_details = {
	open: false,
	key: '',
};

function Filters({ filters, close, set_applied_filters, applied_filters }) {
	const classes = useStyles();
	const [temp_selected_filters, set_temp_selected_filters] = useState({});
	const [date_picker_details, set_date_picker_details] = useState({ ...initial_date_picker_details });

	useEffect(() => {
		if (_.isEmpty(applied_filters)) return;
		let _temp_selected_filters = {};
		_.forEach(filters, (filter, index) => {
			if (!applied_filters[filter.key]) {
				return;
			}
			if (filter.type == 'date_range') {
				_temp_selected_filters[filter.key] = `${moment(applied_filters[filter.key].$gte).format('YYYY-MM-DD')},${moment(
					applied_filters[filter.key].$lte,
				).format('YYYY-MM-DD')}`;
			}
			if (filter.type == 'text') {
				_temp_selected_filters[filter.key] = applied_filters[filter.key];
			}
			if (filter.type == 'drop_down') {
				_temp_selected_filters[filter.key] = applied_filters[filter.key];
			}
			if (filter.type == 'number_range') {
				_temp_selected_filters[filter.key] = { start: applied_filters[filter.key].$gte, end: applied_filters[filter.key].$lte };
			}
			if (filter.type == 'multi') {
				_temp_selected_filters[filter.key] = [...applied_filters[filter.key].$in];
			}
		});
		set_temp_selected_filters(_temp_selected_filters);
	}, []);

	const handle_select_multi_drop_down = (value, filter) => {
		let val = [...(temp_selected_filters[filter.key] || [])];
		const index = _.indexOf(val, value);
		if (index !== -1) {
			val.splice(index, 1);
		} else {
			val.push(value);
		}
		handle_change(filter.key, val);
	};

	const handle_change = (key, value) => {
		set_temp_selected_filters({
			...temp_selected_filters,
			[key]: value,
		});
	};

	const handle_submit = () => {
		let query = {};

		_.forEach(filters, (filter, index) => {
			if (temp_selected_filters[filter.key]) {
				if (Array.isArray(temp_selected_filters[filter.key])) {
					if (temp_selected_filters[filter.key].length > 0) query[`${filter.key}`] = { $in: temp_selected_filters[filter.key] };
				} else if (filter.type == 'date_range') {
					const start_date = temp_selected_filters[filter.key].split(',')[0];
					const end_date = temp_selected_filters[filter.key].split(',')[1];
					query[`${filter.key}`] = { $gte: new Date(moment(start_date).toISOString()), $lte: new Date(moment(end_date).toISOString()) };
				} else if (filter.type == 'number_range') {
					const start = temp_selected_filters[filter.key].start;
					const end = temp_selected_filters[filter.key].end;
					query[`${filter.key}`] = { $gte: Number(start), $lte: Number(end) };
				} else {
					query[`${filter.key}`] = temp_selected_filters[filter.key];
				}
			}
		});
		set_applied_filters(query);
	};
	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container}>
				{_.map(filters, (filter) => {
					return (
						<>
							<Text semi style={{ marginTop: 20 }}>
								{filter.title}
							</Text>

							{filter.type == 'date_range' && (
								<div
									className='cursor-pointer'
									style={{ marginTop: 4 }}
									onClick={() =>
										set_date_picker_details({
											open: true,
											key: filter.key,
										})
									}>
									<div style={{ display: 'flex' }}>
										<Text semi style={{ color: theme.colors.primary }}>
											{temp_selected_filters[filter.key]
												? `${moment(temp_selected_filters[filter.key]?.split(',')[0]).format('DD MMM `YY')} - ${moment(
														temp_selected_filters[filter.key]?.split(',')[1],
												  ).format('DD MMM `YY')}`
												: 'Select Date(s)'}
										</Text>
										<img src={ImageLinks.calendar_solid_circle} className='cursor-pointer' width={20} height={20} />
									</div>
								</div>
							)}
							{filter.type == 'multi' && (
								<Select
									value={temp_selected_filters[filter.key]}
									options={filter.values}
									handleChange={(val) => {
										handle_select_multi_drop_down(val, filter);
									}}
									containerStyles={{ height: 35, width: 160 }}
									menu_styles={{ marginTop: 40 }}
									text_props={{ semi: true }}
									truncate_title={(title) => {
										return _.truncate(title, { length: 16 });
									}}
									multiple={true}
									defaultOption={{ title: 'Select' }}
								/>
							)}
							{filter.type == 'drop_down' && (
								<Select
									value={temp_selected_filters[filter.key]}
									options={filter.values}
									handleChange={(val) => {
										handle_change(filter.key, val);
									}}
									containerStyles={{ height: 35, width: 160 }}
									menu_styles={{ marginTop: 40 }}
									text_props={{ semi: true }}
									truncate_title={(title) => {
										return _.truncate(title, { length: 16 });
									}}
									defaultOption={{ title: 'Select' }}
								/>
							)}
							{filter.type == 'text' && (
								<OutlinedTextField
									style={{ maxWidth: '60%' }}
									size='small'
									value={temp_selected_filters[filter.key]}
									onChange={(e) => {
										handle_change(filter.key, e.target.value);
									}}
								/>
							)}
							{filter.type == 'number_range' && (
								<div style={{ display: 'flex', gap: 10 }}>
									<div>
										<Text>Min</Text>
										<OutlinedTextField
											size='small'
											type={'number'}
											value={temp_selected_filters[filter.key]?.start || ''}
											onChange={(e) => {
												handle_change(filter.key, { ...temp_selected_filters[filter.key], start: e.target.value });
											}}
										/>
									</div>
									<div>
										<Text>Max</Text>
										<OutlinedTextField
											size='small'
											type={'number'}
											value={temp_selected_filters[filter.key]?.end || ''}
											onChange={(e) => {
												handle_change(filter.key, { ...temp_selected_filters[filter.key], end: e.target.value });
											}}
										/>
									</div>
								</div>
							)}
						</>
					);
				})}
				<Dialog open={date_picker_details.open} PaperProps={{ style: { background: 'transparent', maxWidth: 900 }, elevation: 0 }}>
					<DialogContent style={{}}>
						<DateRangePicker
							open={date_picker_details.open}
							toggle={() => set_date_picker_details({ ...initial_date_picker_details })}
							initialDateRange={
								temp_selected_filters[date_picker_details.key]
									? {
											startDate: moment(temp_selected_filters[date_picker_details.key].split(',')[0]),
											endDate: moment(temp_selected_filters[date_picker_details.key].split(',')[1]),
									  }
									: null
							}
							maxDate={new Date()}
							style={{ width: 200 }}
							onChange={(range) => {
								handle_change(
									date_picker_details.key,
									moment(range.startDate).format('YYYY-MM-DD') + ',' + moment(range.endDate).format('YYYY-MM-DD'),
								);
								set_date_picker_details({ ...initial_date_picker_details });
							}}
						/>
					</DialogContent>
				</Dialog>
				<div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
					<ContainedButton onClick={handle_submit}>
						<Text>Submit</Text>
					</ContainedButton>
				</div>
			</div>
		</>
	);
}

export default Filters;
