const Routes = {
  DASHBOARD: {
    path: "/dashboard",
    exact: true,
  },
  HOME: {
    path: "/",
    exact: true,
  },
};

export default Routes;
