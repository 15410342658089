const ImageLinks = {
	logo: require('./logo.png'),
	eye_open: require('./eye-open.svg').default,
	eye_close: require('./eye-close.svg').default,
	arrow_right_grey: require('./arrow-right-grey.svg').default,
	arrow_right_white_no_fill: require('./arrow_right_white_no_fill.svg').default,
	exclamation_error: require('./exclamation-error.svg').default,
	dashboard_selected: require('./dashboard-selected.svg').default,
	dashboard: require('./dashboard.svg').default,
	logoFullWhite: require('./logo-full-white.png'),
	filter: require('./filter.svg').default,
	filter_pink: require('./filter_pink.svg').default,
	arrow_up_circle_solid: require('./arrow-up-purple-solid-circle.svg').default,
	emptyCheckbox: require('./empty-checkbox.svg').default,
	filledCheckbox: require('./filled-checkbox.svg').default,
	calendar_solid_circle: require('./calender.svg').default,
};

export default ImageLinks;
